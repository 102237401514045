import { tagVariants } from '~/components/tag.tsx'
import { useTranslation } from 'react-i18next'
import { cn } from '~/utils/misc.ts'
import { LocaleNavLink } from '~/components/normalized-link.tsx'

export function BlogCategories({
  blogCategories,
  className,
}: {
  blogCategories: {
    link: string
    title: string
  }[]
  className?: string
}) {
  const { t } = useTranslation()
  return (
    <ul
      className={cn(
        'flex flex-wrap items-center justify-center gap-2 pb-16 pt-12',
        className,
      )}
    >
      {[{ link: '/blog', title: t('all') }]
        .concat(blogCategories)
        .map(category => (
          <li key={category.link}>
            <LocaleNavLink
              className={({ isActive }) =>
                tagVariants({
                  variant: isActive ? 'default' : 'outline',
                })
              }
              end
              to={category.link}
            >
              {category.title}
            </LocaleNavLink>
          </li>
        ))}
    </ul>
  )
}
